import AppBanner from "./AppBanner";
import Cards from "./Cards";
import EnhancedDiabetesManagement from "./EnhancedDiabetesManagement";
import Fin from "./Fin";
import HealthcareProfessionals from "./HealthcareProfessionals";
import Jumbotron from "./Jumbotron";
import Waitlist from "./Waitlist";
import WhoAreWe from "./WhoAreWe";
import "./styles.scss";

export default function Home() {
  return (
    <div className="home-container">
      <Jumbotron />
      <Cards />
      <HealthcareProfessionals />
      <Fin />
      <EnhancedDiabetesManagement />
      <WhoAreWe />
      <AppBanner />
      <Waitlist />
    </div>
  );
}
