import { Container } from "@mui/material";

import { Text, Button } from "@mantine/core";

import Man from "../../../Assets/IMG/ManImage.png";
export default function Jumbotron() {
  return (
    <div className="jumbo-container flex-row align-center justify-between width-100">
      <Container
        maxWidth="lg"
        className="jumbo flex-row align-center justify-between"
      >
        <div className="left" data-aos="fade-right">
          <Text fw={600} className="header">
            <span className="blue">Africa's First&nbsp;</span>
            <span className="orange">AI-Powered</span>
            <br />
            <span className="blue">Ecosystem For&nbsp;</span>
            <span className="orange">Personalised Diabetes Care</span>
          </Text>
          <Text className="small blue" my={30}>
            Making personalised diabetes care more affordable and accessible
          </Text>
          <a href="#waitlist">
            <Button className="download">Become a Beta Tester</Button>
          </a>
        </div>
        <div
          className="right flex-row align-end justify-center "
          data-aos="fade-left"
        >
          <img src={Man} alt="" />
        </div>
      </Container>
    </div>
  );
}
