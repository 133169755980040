import { useState } from "react";

import { Container } from "@mui/material";
import { Text, Button, TextInput } from "@mantine/core";

import { useForm } from "@mantine/form";

import { motion } from "framer-motion";

import "./styles.scss";
import {
  DefaultSuccessNotification,
  validateEmail,
} from "../../../Lib/Methods";
import { PerformRequest } from "../../../Lib/usePerformRequest";
import { Endpoints } from "../../../Lib/Endpoints";
export default function Waitlist() {
  const [formError, setFormError] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      phone: "", // Initialize phone with an empty string
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
      name: (value) => (value.length > 0 ? null : "Please enter your name"),
      phone: (value) =>
        /^(\+?\d{1,3}[- ]?)?\d{10,13}$/.test(value) 
          ? null 
          : "Please enter a valid phone number",

    },
    onValuesChange: (values, prev) => {
      if (formError) {
        validateForm();
      }
    },
  });
  const validateForm = () => {
    const { hasErrors } = form.validate();

    setFormError(hasErrors);
    return hasErrors;
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const JoinWaitlist = async () => {
    const hasErrors = validateForm();

    if (!hasErrors) {
      setLoading(true);
      const { name, email, phone } = form.values;
      const r = await PerformRequest<any>({
        method: "POST",
        route: Endpoints.JoinWaitlist,
        data: { email, name, phone },
      });
      setLoading(false);
      if (r && r.status === 200) {
        DefaultSuccessNotification("You have been added to our waitlist!\nYou will be redirected to joint our phone community.");
        /*DefaultSuccessNotification(
          <>
            "You have been added to our waitlist!\n<a href='https://example.com'>Click here</a> to joint our phone community."
          </>
        );*/
        // window.location.href = "https://chat.phone.com/IVBUx1YJLru73kuTBWC2lY";
        window.open(
          "https://chat.phone.com/IVBUx1YJLru73kuTBWC2lY", '_blank'
        )
      }
    }
  };
  return (
    <div className="waitlist-container" id="waitlist">
      <Container maxWidth="lg">
        <Text className="header">Become a Beta Tester</Text>
        <form
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
            JoinWaitlist();
          }}
          className="form"
        >
          <motion.div
            initial={false}
            animate={{
              height: formError ? "300px" : "250px",
            }}
            className="content flex-col align-center justify-between"
          >
            <TextInput
              {...form.getInputProps("name")}
              w="100%"
              name="name"
              placeholder="Full Name"
              spellCheck={false}
              label="Name"
              classNames={{
                input: "input",
                wrapper: "input-wrapper",
              }}
            />
            <TextInput
              {...form.getInputProps("email")}
              name="email"
              w="100%"
              placeholder="you@example.com"
              spellCheck={false}
              label="Email"
              classNames={{
                input: "input",
                wrapper: "input-wrapper",
              }}
            />
            <TextInput
              {...form.getInputProps("phone")}
              name="phone"
              w="100%"
              placeholder="Enter Whatsapp Phone Number"
              spellCheck={false}
              label="Phone Number"
              classNames={{
                input: "input",
                wrapper: "input-wrapper",
              }}
            />
            <Button
              className="button"
              fullWidth
              onClick={JoinWaitlist}
              loading={isLoading}
            >
              Submit
            </Button>
          </motion.div>
        </form>
      </Container>
    </div>
  );
}
